.popup-field {
    margin: 1px 5px;
    padding: 5px;
    display: flex;

}

.popup-field-label{
    padding: 5px;
    flex: 0 0 120px;
    color: var(--fg-color-3);
    font-weight: 400;
}


.popup-field > input,
.popup-field > textarea
 {
    padding: 8px 10px;
    min-width: 100px;
    flex: 1 1;
    margin-bottom: 5px;
    border: 0.5px solid #E5E7EB;
    border-radius: 6px;
    background: #f9fafb;


    color: var(--hg-color-1); 
    font-weight: 300;
    
    font-size: 12px;

}