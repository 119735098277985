.form-new-element{
    width: auto !important;
    margin: 10px auto!important;
    max-width: 500px;
}
.bpmn-setting-button{
    position: absolute;
    right: 15px;
    top: 10px;
    z-index: 1;
    border: none;
    height: 35px;
    width: 35px;
    font-size: 16px;
    color: #ffff;
    box-shadow: 0 0 8px -1px #6a6262;
    border-radius: 6px;
    background-color: var(--fg-color-1);
    outline: none;
}

.bpmn-setting-button:active{
    background-color: var(--fg-color-1);
}

.bpmn-form{
    color: #000;
}

.input-form{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
    flex-basis: 100%;
    text-align: left;
}


.input-form select,.input-form input,.input-form textarea{
    display: inline-block;
    border-radius: 3px;
    border: none;
    box-shadow: 0 0 1px 1px #E5E7EB;    
    width: 100%;
}

.input-form textarea{
    resize: none;
}
.input-form select{
    background-color: #fff;
    height: 30px;
}
.input-form input {
    height: 30px;
}
.input-form label{
    font-weight: 600;
}

.form-toggler{
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 6px;
}
.form-toggler-label{
    color: #0284C7;
    text-align: left;
    min-width: 80px;
    height: fit-content;
    display: inline-block;
}
.form-toggler-point{
    box-shadow: 0 0 8px -2px #ccc;
    height: 12px;
    width: 24px;
    border-radius: 12px;
    cursor: pointer;
    position: relative;
    transition: 0.5s background-color;
}
.form-toggler-point>span{
    display: inline-block;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background-color: #fff;
    position: absolute;
    left: 0;
    transition: 0.5s left;
}

.group-inline{
    display: flex;
    gap: 10px;
}

.bpmn-form{
    display: flex;
    gap: 20px;
    color: #6B7280;
    font-size: 11px;
}

.bpmn-form .section{
    background: #F3F4F6;
    padding: 5px 20px;
    border-radius: 8px;
    width: 400px;
}

.bpmn-form h6{
    margin-top: 5px;
    margin-bottom: 12px;
    color: #1D5A8D;
    text-align: left;
}


.bpmn-form .sous-section{
    margin-bottom: 12px;
}

.bpmn-setting-popup-title{
    color: 1D5A8D;
    text-align: left;
    margin-top: 0;
    margin-bottom: 10px;
}