.Layout {
    background-color: #FFF;
}

@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900");
@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800");
@import url("https://fonts.googleapis.com/css?family=Raleway:400,500,600,700,800");

:root {
    --blue: var(--fg-color-anchor);
    --indigo: var(--fg-color-2);
    --purple: var(--fg-color-1);
    --pink: #f10075;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #3bb001;
    --teal: #00cccc;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #7987a1;
    --gray-dark: #3b4863;
    --primary: var(--fg-color-anchor);
    --secondary: #7987a1;
    --success: #3bb001;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #dc3545;
    --light: #f4f5f8;
    --dark: #3b4863;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
    margin: 0;
    font-family: var(--font-family-list);
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #031b4e;
    text-align: left;
    background-color: #fff !important;
}

[tabindex="-1"]:focus:not(:focus-visible) {
    outline: 0 !important;
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}

ol ol,
ul ul,
ol ul,
ul ol {
    margin-bottom: 0;
}

dt {
    font-weight: 700;
}

dd {
    margin-bottom: .5rem;
    margin-left: 0;
}

blockquote {
    margin: 0 0 1rem;
}

b,
strong {
    font-weight: bolder;
}

small {
    font-size: 80%;
}

sub,
sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
}

sub {
    bottom: -.25em;
}

sup {
    top: -.5em;
}

a {
    color: var(--fg-color-anchor);
    text-decoration: none;
    background-color: transparent;
}

a:hover {
    text-decoration: none;
    font-weight: bold;
}

a:not([href]):not([class]) {
    color: inherit;
    text-decoration: none;
}

a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none;
}

pre,
code,
kbd,
samp {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    font-size: 1em;
}

pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    -ms-overflow-style: scrollbar;
}

figure {
    margin: 0 0 1rem;
}

img {
    vertical-align: middle;
    border-style: none;
}

svg {
    overflow: hidden;
    vertical-align: middle;
}

table {
    border-collapse: collapse;
}

caption {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    color: #7987a1;
    text-align: left;
    caption-side: bottom;
}

th {
    text-align: inherit;
    text-align: -webkit-match-parent;
}

label {
    display: inline-block;
    margin-bottom: 2px;
}

button {
    border-radius: 0;
}

button:focus:not(:focus-visible) {
    outline: 0;
}

input,
button,
select,
optgroup,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

button,
input {
    overflow: visible;
}

button,
select {
    text-transform: none;
}

[role="button"] {
    cursor: pointer;
}

select {
    word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
    -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
    cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
    padding: 0;
    border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
    box-sizing: border-box;
    padding: 0;
}

textarea {
    overflow: auto;
    resize: vertical;
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
}

legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal;
}

progress {
    vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
    height: auto;
}

[type="search"] {
    outline-offset: -2px;
    -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
}

output {
    display: inline-block;
}

summary {
    display: list-item;
    cursor: pointer;
}

template {
    display: none;
}

[hidden] {
    display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
}

h1,
.h1 {
    font-size: 2.1875rem;
}

h2,
.h2 {
    font-size: 1.75rem;
}

h3,
.h3 {
    font-size: 1.53125rem;
}

h4,
.h4 {
    font-size: 1.3125rem;
}

h5,
.h5 {
    font-size: 1.09375rem;
}

h6,
.h6 {
    font-size: 0.875rem;
}

.app-rubon {
    display: flex;
    padding: 5px;
    justify-content: space-between;
    height: var(--rubon-heigt);
    width: 100%;
    align-items: center;
    margin: auto;
    background-color: var(--fg-color-1);
}

.layout-public .app-rubon {
    background-color: #FFF;
    padding: 8px 30px;
}

.layout-public .app-menu-label {
    color: initial;
}

.kls-header-logo-public {
    margin-right: 2rem;
}


.kls-header-center {
    flex: 1 1;
    margin: 0 50px;
    position: relative;
    display: flex;
}

.layout-public .kls-header-center {
    justify-content: center;
}

.kls-header-center .app-menu {
    margin: auto 1rem;
}

.app-menu-label {
    color: white;
    margin: auto 10px;
}


.kls-header-right,
.kls-header-left {
    display: flex;
    align-items: center;
}

.kls-header-right>.vsep {
    height: 16px;
    width: 1px;
    background-color: #eee;
    padding: auto;
}

.kls-profile-menu {
    position: relative;
    margin-left: 15px;
    margin-right: 15px;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: .5rem 0;
    margin: .125rem 0 0;
    font-size: .875rem;
    color: #031b4e;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: 3px;
}

.kls-profile-menu .dropdown-menu {
    position: absolute;
    top: 100%;
    left: auto;
    right: 0px;
    bottom: auto;
    width: 230px;
    border-color: #ccc;
    border-width: 1px;
    background-color: #fff;
    box-shadow: -7px 15px 17px #bbb;
} 

.kls-profile-menu.show .dropdown-menu {
    display: block;
}

.kls-header-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
}

.kls-profile-menu>.kls-img-user:before {
    content: "";
    position: absolute;
    bottom: -33px;
    left: 50%;
    margin-left: -7px;
    width: 14px;
    height: 14px;
    border-color: #cdd4e0 transparent transparent #cdd4e0;
    border-style: solid;
    border-width: 2px;
    transform: rotate(45deg);
    background-color: #fff;
    z-index: 9001;
    display: none;
}

.kls-header-profile .kls-img-user {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
}

.kls-header-profile .kls-img-user .avatar-initials {
    font-size: 40px;
    line-height: 80px;
    height: 80px;
    width: 80px;
}

.kls-profile-menu.show .kls-img-user:before {
    display: block;
}

.kls-profile-menu .dropdown-item {
    position: relative;
    padding: 0 1rem;
    height: 38px;
    display: flex;
    align-items: center;
    font-size: 13px;
    color: #596882;
    width: 100%;
    cursor: pointer;
}

.kls-profile-menu .dropdown-item i {
    font-size: 24px;
    margin-right: 10px;
    width: 24px;
    text-align: center;
    line-height: 0.9;
}

.kls-iconbar-aside .kls-iconbar-body .nav-link {
    font-size: 13px;
    font-weight: 500;
}

.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #596882;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #cdd4e0;
    border-radius: 3px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.kls-header-center .form-control {
    border-color: #ccc;
    border-width: 1px;
    background-color: #fcfcfc;
    transition: none;
    height: 40px;
    box-shadow: 0 0 25px #ddd;
}

.kls-header-center .btn {
    position: absolute;
    top: 0;
    right: 0;
    background-color: transparent;
    height: 40px;
    color: #b4bdce;
    transition: none;
    font-size: 16px;
    padding-right: 13px;
    border-width: 0;
    line-height: 1.538;
    padding: 9px 20px;
    border-radius: 0;
    transition: none;
    min-height: 38px;
}

.kls-header-message>a,
.kls-header-notification>a {
    display: block;
    font-size: 24px;
    color: #FFF;
    position: relative;
    line-height: .9;
    outline: none;
}

.kls-header-notification {
    padding: 5px 10px;
    position: relative;
}


/**ICON BAR **/

.kls-iconbar {

    width: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 2px;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 500;
    border-right: solid 1px #fff;
}

@media (min-width: 992px) {
    .kls-iconbar {
        display: flex;
    }
}

.kls-iconbar .nav {
    width: 100%;
    flex: 1;
    flex-direction: column;
    align-items: center;
    padding: 5px 0;
}

.kls-iconbar .nav-link {
    position: relative;
    padding: 0;
    color: var(--side-menu-color);
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.kls-iconbar .nav-link i {
    font-size: 22px;
}

.kls-iconbar .nav-link i.typcn {
    line-height: 1;
}

.kls-iconbar .nav-link i.typcn::before {
    width: auto;
}

.kls-iconbar .nav-link i.typcn-device-laptop,
.kls-iconbar .nav-link i.typcn-th-large-outline {
    font-size: 25px;
}

.kls-iconbar .nav-link i.fa-desktop {
    font-size: 23px;
}

.kls-iconbar .nav-link:hover,
.kls-iconbar .nav-link:focus {
    color: #596882;
}

.kls-iconbar .nav-link.active {
    position: relative;
    left: 3px;
    z-index: 230;
    color: var(--fg-color-2);
    width: 100%;
    background: #FFF;
    border-top: 1px solid #aaa;
    border-bottom: 1px solid #aaa;
    border-right: 1px solid #FFF;
    border-radius: 0px;
}

.kls-iconbar .nav-link+.nav-link {
    margin-top: 10px;
}

.kls-iconbar-logo {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    line-height: .9;
    color: var(--hg-color-1);
    flex-shrink: 0;
    margin-bottom: 0px;
    position: relative;
    margin-top: 100px;
}

.kls-iconbar-logo::after {
    content: '';
    position: absolute;
    left: 1px;
    height: 3px;
    width: 33px;
    background-color: none;
    /* var(--logo-bottom-border, #224);*/
    border-radius: 0px;
    top: 36px;
}

.kls-iconbar-logo:hover,
.kls-iconbar-logo:focus {
    color: var(--hg-color-1);
}

.kls-iconbar-bottom {
    width: 100%;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.kls-iconbar-help {
    line-height: 0;
    font-size: 22px;
    color: #7987a1;
    margin-bottom: 20px;
}

.kls-iconbar-help:hover,
.kls-iconbar-help:focus {
    color: var(--hg-color-1);
}

.popup-fields-container{
    padding: 10px 40px;
}

.field-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.field-container label{
    margin: 10px;
    align-items: center;
}
.field-container input{
    width: 200px;
    height: 30px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 10px;
}

.create-new-element{
    position: sticky;
    bottom: 20px;
    left: 240px;
    color: #fff; 
    border: none;
    background: #1D5A8D;
    margin-left: 10px;
    padding: 10px;
    color: var(--bg-color-1, #FFF);
    cursor: pointer;
    line-height: 26px;
    border-radius: 5px;
    display: flex;
    box-shadow: 0 0 6px 1px #ccc;
}

.empty-view {
    height: 100%;
    overflow-y: auto;
}
.empty-view .ow-vl-inner{
    height: 100%;
}
.empty-view .ow-screen{
    height: 100%;
    overflow-y: auto;
    margin: -12px;
    overflow-x: hidden;

    }

.kls-under-aside{
    height: 100vh;
    width: 100vw;
    background: rgb(138 123 123);
    position: absolute;
    top: 0;
    left: 50px;
    opacity: 0.6;
    filter: brightness(0.3);
    z-index: 100;
 }


.kls-iconbar-aside {
    width: 300px;
    background-color: #fff;
    position: absolute;
    top: 0;
    left: 50px;
    overflow-y: auto;
    z-index: 100;
    display: none;
    box-shadow: 0 11px 7px #916d6d;
}

.kls-iconbar-aside.show {

    display: block;
    height: 70vh;
    min-height: 400px;
}

.kls-iconbar-header {
    display: flex;
    align-items: start;
    justify-content: space-between;
    padding: 20px;
    padding-right: 0;
    padding-left: 0;
}

.kls-iconbar-header .kls-logo {
    line-height: 1;
}

.kls-iconbar-toggle-menu {
    display: block;
    position: relative;
    line-height: 0;
    color: var(--hg-color-1);
    font-size: 28px;
    opacity: .5;
    padding-right: 10px;
}

.kls-iconbar-toggle-menu:hover,
.kls-iconbar-toggle-menu:focus {
    opacity: 1;
    color: var(--hg-color-1);
}

.kls-iconbar-toggle-menu i:first-child {
    display: none;
}

@media (min-width: 1200px) {
    .kls-iconbar-toggle-menu {
        font-size: 24px;
    }

    .kls-iconbar-toggle-menu::before {
        content: '';
        position: absolute;
        top: 3px;
        bottom: 3px;
        left: -1px;
        border-left: 2px solid var(--hg-color-1);
    }

    .kls-iconbar-toggle-menu i:first-child {
        display: block;
    }

    .kls-iconbar-toggle-menu i:last-child {
        display: none;
    }
}

.kls-iconbar-body {
    padding: 2px;
    padding-left: 0;
    height: 100%;
    background-color: var(--side-menu-body-bg);
}

.kls-iconbar-body .nav {
    flex-direction: column; 
    padding-top: 5px;
    padding-left: 1px;
    margin-top: 5px;
}

.kls-iconbar-body .nav-item {
    position: relative;
    display: block;
    margin-left: 1rem;
}

.kls-iconbar-body .nav-item+.nav-item {
    margin-top: -1px;
    border-top: 1px dotted #cdd4e0;
}

.kls-iconbar-body .nav-item.active .nav-link {
    color: var(--fg-color-2);
}

.kls-iconbar-body .nav-item.active .nav-link i {
    color: var(--fg-color-2);
}

.kls-iconbar-body .nav-item.show .nav-sub {
    display: block;
}

.kls-iconbar-body .nav-link {
    position: relative;
    color: #031b4e;
    font-size: 13px;
    padding: 0;
    display: flex;
    align-items: center;
    line-height: 18px;
    padding: 10px 0;
    font-family: 'Roboto';
    letter-spacing: 0.5px;
    cursor: pointer;
}

.kls-iconbar-body .nav-link:hover,
.kls-iconbar-body .nav-link:focus {
    color: var(--fg-color-2);
}

.kls-iconbar-body .nav-link:hover i,
.kls-iconbar-body .nav-link:focus i {
    color: var(--fg-color-2);
}

.kls-iconbar-body .nav-link.with-sub {
    justify-content: space-between;
}

.kls-iconbar-body .nav-link.with-sub::after {
    content: '\f3d0';
    font-family: 'Ionicons';
    font-size: 11px;
    font-weight: 400;
    display: inline-block;
    position: relative;
    margin-left: auto;
    color: #97a3b9;
}

@media (min-width: 992px) {
    .kls-iconbar-body .nav-link.with-sub::after {
        margin-left: 4px;
        font-size: 12px;
    }
}

.kls-iconbar-body .nav-sub {
    list-style: none;
    padding: 0;
    margin: 0;
    border-top: 1px dotted #cdd4e0;
    display: none;
}

.kls-iconbar-body .nav-sub .nav-sub-item {
    position: relative;
    display: block;
    margin-left: 15px;
}

.kls-iconbar-body .nav-sub .nav-sub-item+.nav-sub-item {
    border-top: 1px dotted #cdd4e0;
}

.kls-iconbar-body .nav-sub .nav-sub-item.active>.nav-sub-link {
    color: var(--fg-color-2);
}

.kls-iconbar-body .nav-sub .nav-sub-link {
    display: flex;
    align-items: center;
    height: 36px;
    color: #031b4e;
}

.kls-iconbar-body .nav-sub .nav-sub-link:hover,
.kls-iconbar-body .nav-sub .nav-sub-link:focus {
    color: var(--fg-color-2);
}

.kls-iconbar-title {
    font-size: 20px;
    font-weight: 700;
    color: var(--fg-color-1);
    margin-bottom: 1px;
    margin-top: 20px;
    padding-left: 10px;
    line-height: 1;
    text-transform: capitalize;
}

.kls-iconbar-text {
    display: block;
    font-size: 12px;
    color: #7987a1;
    margin-bottom: 12px;
}

.kls-iconbar-pane {
    margin-bottom: 20px;
    display: none;
}

.kls-iconbar-pane.show {
    display: block;
}

.client-banner {
    display: none;
}

@media (min-width: 768px) {
    .client-banner {
        background-color: var(--fg-color-1);
        max-height: 150px;
        overflow: hidden;
        position: relative;
        display: block;
    }
}

.client-banner-title {
    position: absolute;
    bottom: 10px;
    left: 20px;
    color: #FFF;
    font-size: 2rem;
    padding: 10px 20px;
    border-radius: 10px;
    background-color: #8884;
}

.client-banner-title p:last-child {
    margin-bottom: 0;
}

.client-banner img {
    display: block;
    width: 100%;
    opacity: .85;
    height: 150px;
}

.nav-link span.title {
    display: none;
    position: absolute;
    top: 70%;
    left: 20px;
    z-index: 200;
    background: var(--fg-color-2);
    color: #FFF;
    padding: 5px 10px;
    border-radius: 0px;
    font-weight: 500;
    text-transform: capitalize;
}

.nav-link:hover span.title {
    display: block;
}

@media (max-width: 768px) {
    .nav-link:hover span.title {
        display: none !important;
    }
}

.main-content-scrollable {
    height: var(--scrollable-area-height);

}

.kls-iconbar-body .kls-iconbar-text {
    display: none;
}


/*scrollbar  width */

::-webkit-scrollbar {
    width: 8px;
}


/*scrollbar Track */

::-webkit-scrollbar-track {
    background: var(--bg-color-scoll-0);
}


/*scrollbar Handle */

::-webkit-scrollbar-thumb {
    background: var(--bg-color-scoll-1);
    border-radius: 0px;
}


/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
    background: var(--bg-color-scoll-2);
}


/** LOGIN **/

/* ###### 7.10 Signup  ###### */

.kls-signup-wrapper {
    flex: 1 1;
    display: flex;
    justify-content: center;
    background-color: rgba(244, 245, 248, 0.2);
    border-radius: 16px;
    box-shadow: 0 0 32px #aaa;
    width: 1050px;
    margin: auto;
}

 

.kls-column-signup-left {
    flex: 1;
    padding: 30px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: none;
}

@media (min-width: 576px) {
    .kls-column-signup-left {
        padding: 40px;
    }
}

@media (min-width: 992px) {
    .kls-column-signup-left {
        display: flex;
    }
}

.kls-column-signup-left>div {
    max-width: 500px;
}

.kls-column-signup-left .typcn {
    font-size: 80px;
    margin-bottom: 50px;
}

.kls-column-signup-left .typcn::before {
    width: auto;
}

.kls-column-signup-left .kls-logo {
    font-size: 40px;
    line-height: 1;
}

.kls-column-signup-left h5 {
    color: var(--hg-color-1);
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 25px;
}

.kls-column-signup-left p {
    line-height: 1.7;
    margin-bottom: 25px;
}

.kls-column-signup-left .btn,
.kls-column-signup-left .sp-container button,
.sp-container .kls-column-signup-left button {
    border-width: 2px;
    padding-left: 25px;
    padding-right: 25px;
    font-weight: 700;
    text-transform: uppercase;
    height: 38px;
}

.kls-column-signup {
    background-color: #fff;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media (min-width: 576px) {
    .kls-column-signup {
        padding: 40px;
        width: 500px;
    }
}

@media (min-width: 992px) {
    .kls-column-signup {
        border-left: 1px solid #cdd4e0;
    }
}

.kls-signup-header h2 {
    font-weight: 500;
    color: var(--fg-color-2);
    letter-spacing: -1px;
}

.kls-signup-header h4 {
    font-size: 20px;
    font-weight: 400;
    color: var(--hg-color-1);
    margin-bottom: 25px;
}

.kls-signup-header label {
    color: #7987a1;
}

.kls-signup-header .form-control {
    color: var(--hg-color-1);
    font-weight: 500;
    border-width: 2px;
    border-color: #cdd4e0;
}

.kls-signup-header .form-control:focus {
    border-color: #b4bdce;
    box-shadow: none;
}

.kls-signup-header .form-control::placeholder {
    font-weight: 400;
    color: #bbb;
    font-size: 12px;
}

.kls-signup-header>.btn,
.sp-container .kls-signup-header>button {
    margin-top: 25px;
}

.kls-signup-header .row {
    margin-top: 20px;
}

.kls-signup-header .row>div .btn i,
.kls-signup-header .row>div .sp-container button i,
.sp-container .kls-signup-header .row>div button i {
    font-size: 15px;
    line-height: 0;
    margin-right: 5px;
}

.kls-signup-header .row>div:first-child .btn,
.kls-signup-header .row>div:first-child .sp-container button,
.sp-container .kls-signup-header .row>div:first-child button {
    background-color: #4267b2;
    color: #fff;
}

.kls-signup-header .row>div:first-child .btn:hover,
.kls-signup-header .row>div:first-child .sp-container button:hover,
.sp-container .kls-signup-header .row>div:first-child button:hover,
.kls-signup-header .row>div:first-child .btn:focus,
.kls-signup-header .row>div:first-child .sp-container button:focus,
.sp-container .kls-signup-header .row>div:first-child button:focus {
    background-color: #375694;
    color: #fff;
}

.kls-signup-footer p {
    color: #7987a1;
    margin-bottom: 0;
}

.kls-signup-footer a {
    color: var(--hg-color-1);
    font-weight: 700;
}

.kls-signup-footer a:hover,
.kls-signup-footer a:focus {
    color: var(--fg-color-2);
}
.menu-expanded-logo-link {
    margin: auto;
}

.menu-expanded-logo {
    display: block;
    width: 180px;
    margin: auto;
    position: relative;
    left: 10px;
}

.btn-tlb {
    margin-left: 10px;
    padding: 4px 16px;
    border: 0.5px solid var(--fg-color-1);
    color: var(--fg-color-1);
    background: var(--bg-color-1);
    cursor: pointer;
    line-height: 26px;
    border-radius: 8px;
    display: inline-block;
}

.action-label {}

.btn-tlb.neg {
    background-color: var(--fg-color-1);
    color: var(--bg-color-1);
}
.btn-tlb:active {
    border-width: 2px;
}
.btn-tlb>i {
    margin: 5px auto;
}

.btn-block {
    position: relative;
}

.btn-block:before {
    content: "";
    position: absolute;
    top: -5px;
    left: -5px;
    border-top: 2px solid var(--fg-color-2);
    border-left: 2px solid var(--fg-color-2);
    width: 5%;
    height: 40%;
    transition: all 2s;
}

.btn-block:after {
    content: "";
    position: absolute;
    right: -5px;
    bottom: -5px;
    border-bottom: 2px solid var(--fg-color-2);
    border-right: 2px solid var(--fg-color-2);
    width: 5%;
    height: 40%;
    transition: all 2s;
}

.btn-block:hover,
.btn-block:active,
.btn-block:visited,
.btn-block:focus {
    background-color: var(--fg-color-2) !important;
    box-shadow: none !important;
}

.btn-block:hover:after,
.btn-block:hover:before {
    width: calc(100% + 10px);
    height: calc(100% + 10px);
}

.pwdToggler {
    position: absolute;
    bottom: 12px;
    right: 12px;
    cursor: pointer;
}

.form-group-pwd {
    position: relative;
}


/**menus**/

i.mnbtn {
    float: left;
    line-height: 32px;
    margin-right: 6px;
    width: 18px;
}

.kls-logo img {}

.kls-avatar {
    display: flex;
    background: #FFF;
    border-radius: 5px;
    cursor: pointer;
}

.kls-img-user {
    min-width: 30px; 
}
.img-user {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.img-user img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%; 
  }
.kls-avatar-tenant {
    line-height: 30px;
    font-size: 15px;
    padding-right: 10px;
    font-weight: bold;
}

.avatar-initials {
    font-size: 22px;
    font-weight: 700;
    border-top-left-radius: 5px;
    color: #FFF;
    display: block;
    height: 32px;
    text-align: center;
    line-height: 32px;
    border-bottom-left-radius: 5px;
    margin-right: 5px;
    padding: 0 5px;
}

.submenu-block {
    margin: 10px 10px;
}

.submenu-block h6.kls-iconbar-title {
    font-size: 15px;
    border-top: 1px solid #ddd;
    padding: 10px 5px 10px 0px;
}

h6.kls-iconbar-title .fa-times {
    position: absolute;
    right: 15px;
    cursor: pointer;
}

.card-text.kpi-description {
    font-weight: 500;
}

.ql-align-justify {
    text-align: justify;
    font-weight: 500;
}

.form-header {
    display: flex;
    justify-content: space-between;
}

.service-board {
    display: flex;
}

.leftside-menu {
    min-width: 50px;
    position: relative;
}



.kls-header-logo {
    display: block;
    height: 32px;
    width: 32px;
    margin: auto;
    margin-right: 10px;
}

.kls-header-entity {
    position: relative;
}
 

.kls-header-entity-label {
    font-size: 14px;
    color: #FFF;
    margin-right: 2rem;
    font-weight: bold;
    line-height: 40px;
}
.kls-header-entity-modelName {
    font-size: 14px;
    color: #FFF;
    margin-right: 2rem;
    font-weight: bold;
    line-height: 20px;
    text-transform: capitalize;
}
.kls-header-entity-moduleName {
    font-size: 12px;
    color: #FFF;
    margin-right: 2rem;
    font-weight: 400;
    line-height: 20px;
}

.kls-header-tab a {
    color: #FFF;
    padding: 2px 10px;
    display: block;
    margin-right: 1rem;
    margin-left: 0.5rem;
    height: 20px;
    box-sizing: content-box;
    border: 1px solid #FFF;
    border-radius: 5px;
    text-transform: capitalize;
}

.kls-header-tab a.selected {
    color: orange;
    border-color: orange;
}

.kls-header-catalog {
    padding: 5px 10px;
    padding-left: 10px;
    height: 50px;
    line-height: 50px
}

.kls-header-catalog i {
    color: #FFF;
    font-size: 16px;
}

span.kls-header-catalog-label {
    font-size: 15px;
    font-weight: 500;
    margin: auto 10px auto 5px;
    cursor: pointer;
    color: #FFF;
    line-height: 39px;
}

.ow-vbox.form-header {
    border: 1px solid #CCC9;
    margin: 0.5rem 0.8rem;
    padding: 0.7em 2em;
    width: auto;
    text-align: center;
    background: var(--k6-form-header-box-bg);
    border-radius: 3px;
    overflow: hidden;
    box-shadow: 3px 3px 3px #0004;
}



.service-editor {
    height: 100%;
    display: flex;
    overflow: hidden;
}

.file-editor {
    padding-top: 10px;
    height: 100%;
    flex: 1 1;
}


 

.save-app-icon {
    padding: 10px 15px;
    border-right: 1px solid #FFF;
    padding-left: 15px;
    height: 50px;
    line-height: 50px;
}

.save-app-icon i {
    display: block;
    font-size: 24px;
    color: #FFF;
    position: relative;
    line-height: 30px;
    outline: none;
    padding: 0;
    cursor: pointer;
}


.nav-item-sitem {
    padding-left: 15px;
}

.nav-item-sitem>li {
    list-style: none;
}


.form-container {
    width: 300px;
    margin: 0 auto;
  }
  
  .field-container {
    margin-bottom: 20px;
  }
  .form-container label,
  .popup-fields-container label {
    display: block;
    /* font-weight: bold; */
    margin-bottom: 5px;
    /* letter-spacing: 1px; */
    font-size: 15px;
  }
  .view-config span{
    font-weight: bold;
    margin: 17px;
    font-size: 2em;
  }
  .view-config .fa{
    color: #1d5a8d;
    font-size: 23px;
    cursor: pointer;
  }
  .form-container input,.form-container select{
    width: 100%;
    padding: 8px;
    /* border: 1px solid #ccc; */
    border: none;
    box-shadow: 0 0 1px 0px #ccc;
    border-radius: 4px;
  }
  .template-options{
    display: flex;
  flex-wrap: wrap;
  justify-content: space-between; 
  max-width: 500px; 
  margin: 0 auto;
  }
  .card-template {
    flex: 0 0 calc(33.33% - 10px); 
    box-sizing: border-box;
    /* border: 1px solid #ccc;  */
    border: none; 
    box-shadow: 0 0 1px 0px #ccc;
    padding: 10px;
    text-align: center;
    margin-bottom: 20px; 
  }
  .card-template img {
    height: 73%;
    width: 100%;
    box-shadow: 0px 0px 9px 1px #EEE;
    cursor:pointer;
    border: 3px transparent solid;
  }
  
  .card-template p {
    font-weight: bold;
  }

.selected-image{
    border: 3px #1d5a8d solid!important;
    border-radius: 5px;
}
.partition-gbox .ow-gbox{
    height:100%;
}
.partition-style{
    background-color: white;
    margin:10px;
    border-radius:5px;
}
.icon-svg {
    height: 30px;
    margin: 4px;
}

.cms-page {
    max-width: 1200px;
    margin: 4rem auto;
}

.cms-page-title {
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 150%;
    text-align: center;
    color: #0369A1;
}

.cms-page-parag {
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 150%;

    text-align: center;
    color: #1F2937;
}

.cms-page-calltoaction {
    padding: 1rem;
    margin-top: 3rem;
    display: flex;
    color: #0369A1;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 150%;

    display: flex;
    align-items: center;
    text-align: center;
    color: #0369A1;
    justify-content: end;
}

.cms-page-calltoaction>span {
    margin: auto 1rem;
}


.login-popup {
    position: fixed;
    width: 100vw;
    height: 100vh; 
    display: flex;
    justify-content: center;
    align-items: center;
    top:0;
    left: 0;
    background: #6B728080; 
}

.login-popup-inner {
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem 2rem;


    position: absolute;
    width: 500px;
    min-height: 280px;
    justify-content: space-between;

    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 8px 16px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
}

.login-title {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 150%;
    color: #0369A1;
    margin-bottom: 1rem;

}


.login-popup-inner a { cursor:  pointer;}

input.login-input {
    background: #FFFFFF;
    border: 1px solid #EAEAEA;
    border-radius: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px; 
    padding: 15px 20px 15px 16px;
    width: 100%;

}

.login-actions-bar {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;
    width: 100%;
    gap: 1rem;
}

.forgot-pwd {
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;

    text-align: center;

    color: #0369A1;
    margin-right: 1rem;
}
 
.service-action-btn.fb19 {
    width: 100%;
    margin: 1.2rem 0 0.6rem;
}
.action-bar .service-action-btn.fb18 {
    flex: 0 0 180px;
}
.action-bar i {
    margin-right: 1rem;
}
.h-c2 {
    display: flex;
    justify-content: space-between;     
    width: 100%;
    flex-wrap: wrap;
}


.h-c2  div {
    flex: 0 0 48%;
    
}

.hg {
    display: flex;
    gap: 8px;
}

.signup-left-side {
    padding: 3rem 2rem;
    border-right: 1px solid #ddd;
    flex: 0 0 300px;
}
.kls-card-signup {
    flex: 1 1;
}

.signup-logo-container {
    
    margin: 2rem 0 ;
}

.kls-signup-title {
    font-size: 18px; 
    font-weight: bold;
    margin-bottom: 8px;
}

.kls-signup-description {
    font-size: 13px;
    font-size: 12px;
    text-align: justify;
    font-weight: 400;
    color: #555;
}

.inprogress-inner {
    position:  fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: #eee;
    opacity: 0.75;
    display: flex;
    justify-content: center;
    align-items: center;
}

.inprogress-inner.timed-out {
    opacity: 1;
}
.inprogress-inner .spinner {
    font-size: 6rem;

}
.oups-message {

    font-size: 1rem;
    color: #222;
}


.dropdown-module-explorer {
    position: absolute;
    top: 54px;
    left: 0;
    width: 700px;
    z-index: 200;
    background: #f8f8f8;
    min-height: 300px;
    padding: 1rem 2rem;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    box-shadow: 0 10px 18px #bbb;
}
.dropdown-module-explorer .close-btn {
    color: #031b4e;
    position: absolute; 

    top: 6px;
    right: 6px;
    cursor: pointer;
    border-radius: 20px ;
    width: 30px;
    height: 30px;
    padding: 10px;
}

.dropdown-module-explorer .close-btn:hover { 
    background-color: #ddd;
}

.modules-explorer-anchore {
    cursor: pointer;
}




.modules-explorer  .dd-lst-container {
    background: #fff;
    border: 1px solid #eaeaea;
    border-radius: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;  
    width: 100%;
}
.modules-explorer .dd-lst-options-ul li {
    padding-top: 6px;
    padding-bottom: 6px;
}
.modules-explorer  .dd-lst-text.filled {
    font-size: 13px;
    font-weight: 500;
}

.modules-explorer-module {
    display: flex;
    justify-content: start;
    grid-gap: 1rem;
    gap: 1rem;
    margin: 1rem;

}
.modules-explorer-module>label {
    flex: 0 0 120px;
    line-height: 30px;
    text-align: right;
    padding-top: 2px;
    margin-bottom: 0;
    color: #1f3546;
    font-size: 13px;
}
.modules-explorer-module>.dd-lst-container {
    flex: 1 1;
}
.modules-explorer-module> .service-action-btn i {

    margin-right: 8px;
}
.modules-explorer-module> .service-action-btn {
      flex: 0 0 83px;
    padding: 4px 12px;
    line-height: 27px;
    font-size: 12px;
}
.modules-explorer-search-bar {
    display: flex;
    justify-content: start;
    grid-gap: 1rem;
    gap: 0rem;
    margin: 1rem;
}

.modules-explorer-search-bar .search-by-text { 
    flex: 0 0 400px;
    position: relative;
}
.modules-explorer-search-bar .search-by-text i {  
    position: absolute;
    opacity: 0.5;
    top: 12px;
    right: 10px;

}

 

.modules-explorer-search-bar .search-by-text input{
    box-sizing: border-box;
    padding: 10px 16px;
     
    background: #fff;
    border: 1px solid #eaeaea;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
    width: 100%;
}

.modules-explorer-search-bar > .dd-lst-container{
    flex: 1 1;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
    border-left:none;
}


.modules-explorer  .tabs-block .tab {
    font-size: 13px;
}
.modules-explorer  .tabs-block .tab.selected {
    font-size: 14px;
    font-weight: 700;
}


.modules-explorer .tab-content {
    padding: 1rem 2rem;
    max-height: 50vh;
    overflow: auto;

}
.btn-link  {
    border: none;
    background: none;
}

.rubric-item {
    margin-bottom: 8px;
    cursor: pointer;
}
.link-icon  {
    margin-right: 8px;
}

.link-label  {
    margin-right: 8px;
    font-size: 12px;
    
}


.btn-link  {
    border: none;
    background: none;
}


input.save-tag , textarea.save-comment {    
    width: 95%; 
    background: #F9FAFB;
    border: 1px solid #F3F4F6;
    border-radius: 8px;
    font-size: 14px;
    line-height: 150%;
    padding: 9px 1rem;
    margin: 8px auto 4px 10px;
}


.tag-info   {
    display: flex;
    justify-content: space-between;
}
.tag-info  .tag  {
    display: flex;
    justify-content: start;
}

.tag-info  .tag-date  {
    font-size: 12px;
}

.tag-message  {
    padding-left: 40px;
    
}

.tag-search-outer {
    position: relative;
}
.tag-search-outer i {
    position: absolute;
    right: 20px;
    top: 6px;
    font-size: 18px;
    color: var(--bg-color-scoll-2);
    cursor: pointer;

}
input.tag-search {  width: 96%;
    height: 32px;
    background: #F9FAFB;
    border: 1px solid #e5e6e8;
    border-radius: 3px;
    font-size: 14px;
    line-height: 150%;
    padding: 0 1rem;
    margin: auto 2%;

}

.empty-message {
    margin: 1rem;
    text-align: center;
    color: #999;
}